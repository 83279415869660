<template>
  <div class="mt-4">
    <div class="mt-4">
      <h4>Lokasyon Seçimi</h4>
      <v-col>
        <!-- <v-autocomplete
          dense
          hide-details
          outlined
          v-model="search"
          :items="locationItems"
          item-text="description"
          item-value="place_id"
          label="Lokasyon Arama"
          @update:search-input="searchLocation"
          @change="selectLocation"
        ></v-autocomplete> -->
        <v-text-field
          outlined
          dense
          hide-details
          @input="getAutocomplete"
          label="Lokasyon Arama"
          style="z-index: 99999"
          id="gmaps-autocomplate"
          :model-value="search"
          type="text"
        />
      </v-col>
    </div>
    <div class="mb-4">
      <v-row>
        <v-col cols="12" md="6">
          <v-text-field
            label="Enlem"
            dense
            hide-details
            @input="updateLocationFromInput"
            v-model.number="initialLocation.lat"
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            label="Boylam"
            dense
            hide-details
            @input="updateLocationFromInput"
            v-model.number="initialLocation.lng"
            outlined
          ></v-text-field>
        </v-col>
      </v-row>
    </div>
    <GmapMap
      :center="initialLocation"
      :zoom="16"
      style="width: 100%; height: 300px"
    >
      <GmapMarker
        :position="initialLocation"
        :draggable="true"
        @dragend="updateCoordinates"
      />
    </GmapMap>
  </div>
</template>

<script>
import { Loader } from "@googlemaps/js-api-loader";
export default {
  name: "LokasyonSecimi",
  props: {
    initialLocation: {
      type: Object,
      required: false,
      default: () => ({ lat: 40.7128, lng: -74.006 }), // Varsayılan konum
    },
  },
  data() {
    return {
      search: "",
      location: this.initialLocation,
      locationItems: [],
      autocompleteService: null,
    };
  },
  async mounted() {
    //this.$nextTick(async () => {
    // const input = document.getElementById("gmaps-autocomplate").value;
    // const loader = new Loader({
    //   apiKey: process.env.VUE_APP_GOOGLE_API_KEY,
    //   version: "weekly",
    // });
    // const places = await loader.importLibrary("places");
    // const options = {
    //   fields: ["address_components", "formatted_address", "geometry"],
    // };
    // const autocomplete = new places.Autocomplete(input, options);
    // autocomplete.addListener("place_changed", async () => {
    //   const place = autocomplete.getPlace();
    //   if (place.geometry && place.geometry.location) {
    //     const newCoordinat = {
    //       lat: place.geometry.location.lat(),
    //       lng: place.geometry.location.lng(),
    //     };
    //     this.updateLocation(newCoordinat);
    //   } else {
    //     console.error("Geçersiz yer seçimi.");
    //   }
    // });
    //});
  },
  methods: {
    async getAutocomplete() {
      const input = document.getElementById("gmaps-autocomplate");
      const loader = new Loader({
        apiKey: process.env.VUE_APP_GOOGLE_API_KEY,
        version: "weekly",
      });

      const places = await loader.importLibrary("places");

      const options = {
        fields: ["address_components", "formatted_address", "geometry"],
      };

      const autocomplete = new places.Autocomplete(input, options);

      autocomplete.addListener("place_changed", async () => {
        const place = autocomplete.getPlace();

        if (place.geometry && place.geometry.location) {
          const newCoordinat = {
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
          };
          this.updateLocation(newCoordinat);
        } else {
          console.error("Geçersiz yer seçimi.");
        }
      });
    },
    updateCoordinates(event) {
      this.initialLocation = {
        lat: event?.latLng.lat(),
        lng: event?.latLng.lng(),
      };
      this.$emit("location-updated", this.initialLocation);
    },
    // searchLocation(newVal) {
    //   const service = new google.maps.places.AutocompleteService();
    //   const options = {
    //     fields: ["address_components", "formatted_address", "geometry"],
    //   };

    //   service.getPlacePredictions(
    //     { input: newVal, options },
    //     (predictions, status) => {
    //       if (
    //         status === google.maps.places.PlacesServiceStatus.OK &&
    //         predictions
    //       ) {
    //         this.locationItems = predictions;
    //       }
    //     }
    //   );
    // },
    updateLocation(newLocation) {
      this.initialLocation.lat = newLocation.lat;
      this.initialLocation.lng = newLocation.lng;
      this.$emit("location-updated", this.initialLocation);
    },
    // selectLocation(placeId) {
    //   const service = new google.maps.places.PlacesService(
    //     document.createElement("div")
    //   );
    //   service.getDetails({ placeId }, (place, status) => {
    //     if (
    //       status === this.google.maps.places.PlacesServiceStatus.OK &&
    //       place.geometry
    //     ) {
    //       this.initialLocation = {
    //         lat: place.geometry.location.lat(),
    //         lng: place.geometry.location.lng(),
    //       };
    //       this.$emit("location-updated", this.initialLocation);
    //       this.search = "";
    //     }
    //   });
    // },
    // initAutocomplete() {
    //   const autocomplete = new google.maps.places.Autocomplete();

    //   autocomplete.addListener("place_changed", () => {
    //     const place = autocomplete.getPlace();
    //     if (place.geometry) {
    //       this.initialLocation = {
    //         lat: place.geometry.location.lat(),
    //         lng: place.geometry.location.lng(),
    //       };
    //     }
    //   });
    // },
    updateLocationFromInput() {
      this.$emit("location-updated", this.initialLocation);
      this.search = "";
    },
    resetData() {
      this.search = "";
      this.location = { ...this.initialLocation };
      this.locationItems = [];
      this.latitude = this.initialLocation.lat;
      this.longitude = this.initialLocation.lng;
    },
  },
  watch: {
    // search(newVal) {
    //   if (!this.autocompleteService) {
    //     this.initAutocomplete();
    //   }
    //   this.searchLocation();
    // },
    initialLocation: {
      handler(newVal, oldVal) {
        this.initialLocation = newVal;
      },
      deep: true,
    },
  },

  computed: {},
};
</script>

<style scoped>
/* Gerekirse stil eklemeleri yapabilirsiniz */
.pac-container {
  z-index: 999999;
}
</style>
